.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;
  flex-grow: 1;

}
.container p {
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  color: #000000;
  margin-bottom: 20px;
}
.container h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 2.8rem;
  line-height: 50px;
  font-weight: 600;
  color: black;
  margin-left: 20px;
  margin-right: 20px;
}
.highlite {
  color:rgb(7, 107, 102);
}
.innertopcontainer {
  width: auto;
  display: flex;
  justify-content: center;
  margin-top: 0px;
}
.innercontainer {
  max-width: 100vw;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 35px;
}

.listcontainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  justify-content: center;
  
  
}

@media (max-width: 480px) {
  .container {
    padding: 0px; /* Adjust spacing as needed */
    justify-content: center;
    text-align: center;
  }

  .innertopcontainer {
    margin-top: 10px; /* Adjust spacing as needed */
  }

  .innercontainer {
    max-width: 100%;
    padding: 0 10px; /* Adjust spacing as needed */
  }

  .listcontainer {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0;
    justify-content: space-evenly;
  }
}

.countrycontainer {
  background-color: #f2f4f8;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 0px;
  flex-grow: 1;
  position: relative;
}

.countrycontainer p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
}
.countrycontainer h1 {
  font-family: "League Spartan", sans-serif;
  font-weight: 300px;
}
.bottomImg {
  width: 100px;
  height: 75px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.topImg {
  width: 90px;
  height: 60px;
  position: absolute;
  top: 0px;
  right: 0px;
}
