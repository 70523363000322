.gallerycontainer {
  padding: 20px;
  background-color: black;
}

.gallerytitle {
  display: flex;
  font-size: large;
  justify-content: center;

  font-family: "League Spartan", sans-serif;
  font-size: 2.3rem;
  padding: 30px;
  line-height: 10px;
  font-weight: 500;
  color: white;
  margin: 20px;
}

.imagegrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Each row has 4 columns */
  gap: 10px;
}

.imagecontainer {
  border-radius: 15px;
  overflow: hidden;

  position: relative;
}

.galleryimage {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .gallerytitle {
    display: flex;
    font-size: large;
    justify-content: center;
  
    font-family: "League Spartan", sans-serif;
    font-size: 1.7rem;
    padding: 30px;
    line-height: 10px;
    font-weight: 500;
    color: white;
    margin: 20px;
  }

  .imagegrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Each row has 4 columns */
    gap: 10px;
  }
}