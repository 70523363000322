.herobox {
  min-height: 90vh;
  padding: 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.spanedtext {
  color: rgb(7, 107, 102);
}
.ctabtn {
  text-decoration: none;
  background: #067adf;
  padding: 10px 30px;
 
  border-radius: 10px;
  color: white;
}
.herobox .herodetails {
  font-family: "Montserrat", sans-serif;
  width: 40%;
  margin-left: 120px;
  margin-right: 30px;
}

.herobox .heroheading {
  font-family: "Montserrat", sans-serif;
  font-style: bold;
  font-size: 3rem;
  line-height: 65px;
  color: black;
}

.herodesc {
  font-family: "Poppins", sans-serif;
  color: black;
  font-size: 16px;
  line-height: 30px;
  margin: 20px 0;
}

.btnbox {
  margin-top: 60px;
}

.herobox .herodetails {
  background: #ffffff;
  padding: 15px 30px;
  margin-right: 30px;
  border-radius: 50px;
}

.herobox .herodetails {
  background: rgba(255, 255, 255, 0.2);
}
.ctabtn:hover {
  background-color: rgb(7, 107, 102);
}

.herobox .herodetails .demo {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.herobox .heroimage {
  width: 60%;
}

.herobox .heroimage:hover {
  color: #ffffff;
  padding-left: 8px;
}
.herobox .heroimage img {
  width: 80%;
}

/* Media queries */


/* @media (max-width: 767px) {
  .herobox {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .herobox .herodetails {
    font-family: "Poppins", sans-serif;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .heroheading {
    font-size: 1.5rem;
  }
  .btnbox {
    margin-top: 10px;
  }
  .ctabtn {
    text-decoration: none;
    background: #067adf;
    padding: 10px 30px;
    font-size: 1rem;
    border-radius: 10px;
    color: white;
    margin-right: 0;
    margin-top: 0;
  }
  .herobox .heroimage {
    padding-right: 60px;
  }
  .herobox .heroimage img {
    max-width: 300px;
  }
} */


@media (max-width: 767px) {
  .herobox {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px; /* Adjust the gap between elements for mobile view */
 height: auto;
  }

  .herobox .herodetails {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center; /* Center the text in mobile view */
  }

  .herobox .heroheading {
    font-size: 2rem; /* Adjust the font size for mobile view */
    line-height: 40px;
  }

  .herobox .heroimage {
    width: 90%; /* Make the image container take up 100% width of its parent on mobile view */
    min-height: 100pt;
    text-align: center; /* Center the image horizontally */
  }

  .herobox .heroimage img {
    max-width: 100%; /* Make the image fill the container width while maintaining its aspect ratio */
  }

  .btnbox {
    margin-top: 30px; /* Adjust the margin for mobile view */
 padding: 0px;
  }
}