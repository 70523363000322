.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh; /* Adjust the height as needed */
  
  }

  .leftcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

  }
  
  .leftcontainer img {
    padding: 20px;
    max-width: 70%;
  }
.rightcontainer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-left: 50px;
}
  .spanedtext {
    color: rgb(7, 107, 102);
  }
.title{
  font-family: 'League Spartan', sans-serif;
  font-size: 2.5rem;
  line-height: 50px;
  font-weight: 600;
  color: rgb(1, 0, 0);
  margin-left: 20px;
  margin-right: 20px;
}

 .desc {
  font-family: 'Poppins', sans-serif;
  color: #010000;
  font-size: 16px;
  line-height: 28px;
  margin: 20px 20px;
}


/* Media query for mobile devices with a maximum width of 767 pixels */
@media (max-width: 767px) {
  .container {
    grid-template-columns: 1fr; /* Single column layout for mobile view */
    height: auto; /* Remove fixed height for mobile view */
  }

  .leftcontainer img {
   
   min-height: 100pt;
    padding: 10px; /* Reduce padding for mobile view */
   
  }

  .rightcontainer {
    padding: 10px; /* Reduce padding for mobile view */
    justify-content: center;
    align-items: center;
   
  }

  .title {
    text-align: center;
   
 
  }

  .desc {
    text-align: center;
   
    margin: 10px; /* Adjust margin for mobile view */
  }
  .textcontainer{
   align-items: center;
   justify-content: center;
   margin-top: 10px;
  }
}

