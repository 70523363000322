@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700&display=swap');
body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.code {
background-color: aqua;


}


.card {
  width: 180px;
  height: 250px;
  background-color: rgba(179, 222, 245, 0.356);
  border-radius: 10px;
  margin: 5px 5px;
  padding: 5px;
  box-shadow: 0 2 5px #ccc;
  display: inline-block;
}

.note-title {
  color: black;
  font-size: 1rem;
  text-align: center;
}

.note-body {
  color: grey;
  font-size: 0.8rem;
  text-align: center;
  
}
.listcontainer {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  justify-content: center;
}
.textbutton{
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  color: #067adf;
}
