
/* notification type card */

.card{
    width: 40%;
    height: auto;
    border-radius: 10px;
    margin: 10px 10px;
    box-shadow: 0 2 5px #ccc;
    display: flex;
  
    
}
@media (max-width: 767px) {
  .card{
    width: 250px;
    height: auto;
    border-radius: 10px;
   padding: 10px;
    box-shadow: 0 2 5px #ccc;
    display: flex;
  
    
}
.notificationCard {
  width: 180%;
  height: 200px;
 
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
 
  border-radius: 10px;
 overflow: hidden;
}
}
  .notificationCard {
    width: 180%;
    height: auto;
   
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: justify;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 10px;
   overflow: hidden;
  }

  .bottomImg{
    width: 60px;
    height: 60px;
  }
  
  .bellIcon {
    width: 50px;
    margin: 20px 0px;
  }
  
  .bellIcon path {
    fill: rgba(168, 131, 255, 0);
  }
  
  .notificationHeading {
    color: black;
    font-weight: 600;
    font-size: 1.2em;
    text-align: center;
    line-height: normal;
    margin:0px;
  }
  
  .notificationPara {
    font-family: "Poppins", sans-serif;
    color: #808080;
    font-size: 0.8em;
    line-height: normal;
    text-align: center;
  
 
  }

  .stepcontainer{
    width: auto;
    height: 25px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: aqua;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
 
  }
  .stepcontainer p{
    font-family: "Poppins", sans-serif;
    color: #808080;
    font-size: 0.8em;
    font-weight: 400;
    text-align: center;
    line-height: normal;
  }
  
  /* .card{
    flex-basis: 23%;
    margin: 10px 10px;
    text-align: center;
    padding: 20px 0px;
    box-shadow: 0 0 10px 3px lightgray;
  } */