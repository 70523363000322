@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700&display=swap');
.header {
  margin-top: -10px;
  padding-top: 10px;
  min-width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  background-color: #f2f8f7;
}
.logotext{
  float: left;
  margin-left: 20px;
  height: auto;
  width: 10rem;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: #050606;
}
.links{
  cursor: pointer;
}

.logo {
  float: left;
  margin-left: 20px;
  height: 50px;
  width: auto;
}

.logo a {
  text-decoration: none;
  color: #050606;
}

.list {
  list-style: none;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10;
  order: 2;
  
}
.navigation a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;

  color: #050606;
  font-size: 1.4rem;
  margin-right: 2rem;
}

.navigation a:hover {
  color: darkslateblue;
  font-size: medium;
  border-bottom: 1px solid #05235b;
  position: relative;
}

.navigation a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: blue; /* Adjust the color as needed */
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navigation a:hover::after {
  transform: scaleX(1);
}

.button {
  order: 1;
}

@media (min-width: 768px) {
  .logo {
    font-size: 1.4rem;
  }

  .navigation a {
    font-family: "Montserrat", sans-serif;
    font-size: 0.8rem;
  }
}

.login {
  width: 100px;
  height: 30px;

  background-color: dodgerblue;
  color: white;
  border-radius: 5px;
  border: 0px;
  margin-right: 20px;
  margin-left: 20px;
}
.menuicon {
  display: none;
  cursor: pointer;
  margin-right: 18px;
}

.dropdown{
  display: none;
  position: absolute;
  right: 2rem;
  top: 60px;
  width: 350px;
  height: 0px;
  background: rgb(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  overflow: hidden;
 transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown.open{
  min-height: 420px;
}

.dropdown li{
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Poppins", sans-serif;

  color: #050606;
  font-size: 1rem;
  
}
.dropdown ul{
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}
.dropdown ul a{
 
  color: black;
  text-decoration: none;
}
.dropdown a:hover {
  color: darkslateblue;
  font-size: medium;
  border-bottom: 1px solid #05235b;
  position: relative;
}
.dropdownlogin {
  width: 100%;
  margin-bottom: 16px;
  height: 30px;

  background-color: dodgerblue;
  color: white;
  border-radius: 5px;
  border: 0px;
  margin-right: 20px;
  margin-left: 20px;
}

@media (max-width: 992px) {
.navigation .list,
.login{
  display: none;
}
.menuicon{
  display: block;
}
.dropdown{
  display: block;
}
}

@media (max-width: 767px) {
  .header{
    min-width: 100vw;
    margin-right: 0px;
  }
  .dropdown{
    left: 2rem;
    width: unset;
  }
}

/* Media query for responsive navigation */
/* @media (max-width: 768px) {
  .menu {
    display: none;
  }

  .menuicon {
    display: block;
  }

  .menuicon.active .menuicon__line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .menuicon.active .menuicon__line:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.active .menuicon__line:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -7px);
  }

  .menu.active {
    display: block;
    margin-top: 10px;
  }

  .menu.active li {
    margin: 10px 0;
  }
} */
