.footer {
  max-width: 100%;
  margin: 0;
  padding: 0;
  max-width: 2500px;
  line-height: 1.5;
  background-color: #242b2a;
  font-family: "Poppins", sans-serif;
}
.imagesection{
  padding-top: 30px;
}
.logo {
  float: left;
  margin-left: 0px;
  height: 2.5rem;
  width: auto;
}

.logo a {
  text-decoration: none;
  color:#bbbbbb;
}
.logotext{
  float: left;
  margin-left: 20px;
  height: auto;
  width: 10rem;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: white;
}
.container {
  margin: auto;
  padding: 30px;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}
.footerCol {
  width: 20%;
  padding: 0 10px;
}
.footerCol h4 {
  font-family: "League Spartan", sans-serif;
  font-size: 18px;
  color: white;
  text-transform: capitalize;
  margin-bottom: 5px;
  margin-left: 0;

  font-weight: 500;
  position: relative;
}
.footerCol h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;

  background-color:  rgb(7, 107, 102);;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}
.footerCol ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footerCol ul li a {
  font-size: 0.8rem;
  text-transform: capitalize;
  margin-left: -40px;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}
.footerCol ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}

/*responsive*/
@media (max-width: 767px) {
  .footer{
 min-width: 100vw;
  }
  .footerCol {
    width: 40%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .footerCol {
    width: 100%;
  }
}
.links{
  cursor: pointer;
}

.powered{
  background-color: black;
  display: flex;
  justify-content: center;
  font-family: "League Spartan", sans-serif;
  font-weight: 400;
  color: white;
  padding: 30px;
}
.powered span{
  color: rgb(107, 233, 242)
}