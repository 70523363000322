.formInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}

input {
  padding: 15px;
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid gray;
}

label {
  font-size: 12px;
  color: gray;
}

input:invalid[focused="true"] {
  border: 1px solid red;
}

input:invalid[focused="true"] ~ span {
  display: block;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 80vh; /* Adjust the height as needed */
}

.leftcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.leftcontainer img {
  padding: 10px;
  max-width: 60%;
}
.rightcontainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 10px;
  margin-left: 10px;
}
.spanedtext {
  color: rgb(7, 107, 102);
}

.infotitle {
  font-family: "Montserrat", sans-serif;

  line-height: 50px;
  font-style: bold;

  color: red;
  margin-left: 20px;
  margin-right: 20px;
}
.title {
  font-family: 'League Spartan', sans-serif;
  font-size: 2.5rem;
  line-height: 50px;
  font-weight: 600;
  color: rgb(1, 0, 0);
  margin-left: 20px;
  margin-right: 20px;
}

.desc {
  font-family: "Montserrat", sans-serif;

  color: #383838;
  font-size: 14px;
  line-height: 28px;
  margin: 20px 20px;
}

/* Media query for mobile devices with a maximum width of 767 pixels */
@media (max-width: 767px) {
  .chips {
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    justify-content: center;
    row-gap: 10px;
  }
  .chiptitle {
    text-align: center;
  }
  .submitbtn {
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .formdata {
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container {
    grid-template-columns: 1fr; /* Single column layout for mobile view */
    height: auto; /* Remove fixed height for mobile view */
  }

  .leftcontainer img {
    min-height: 100pt;
    padding: 10px; /* Reduce padding for mobile view */
  }

  .rightcontainer {
    padding: 10px; /* Reduce padding for mobile view */
    justify-content: center;
    align-items: center;
  }

  .title {
    text-align: center;
  }

  .desc {
    text-align: center;

    margin: 10px; /* Adjust margin for mobile view */
  }
  .textcontainer {
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  input {
    padding: 15px;
    margin: 0px 0px;
    border-radius: 5px;
    border: 1px solid gray;
  }
}
