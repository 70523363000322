.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;
  
  justify-content: center;
  align-items: center;
  background-color: #f2f4f8;
}
  .container p {
    display: flex;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    color: #000000;
    margin-bottom: 0px;
  }
  .container h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 300px;
    color: black;
  }
  .highlite {
    color: rgb(7, 107, 102);
    padding-left: 10px;
    padding-right: 10px;
  }
  .titlehead{
    font-family: 'League Spartan', sans-serif;
    font-weight: 800;
    font-size: 1rem;
    justify-content: center;
    color: black;
    display: flex;
  }
  .innertopcontainer {
    width: auto;
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }
  .innercontainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Each row has 4 columns */
    gap: 10px;

  }
  
  .listView {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
  }

  @media (max-width: 767px) {
    .titlehead {
      font-family: 'League Spartan', sans-serif;
      font-weight: 600;
      font-size: 1rem;
      text-align: center; /* Center-align the text */
      color: black;
    }
    .highlite {
      color: rgb(7, 107, 102);
      padding-left: 0px;
      padding-right: 0px;
    }
    .container {
      padding: 0px; /* Adjust spacing as needed */
      justify-content: center;
      text-align: center;
    }
  
    .innertopcontainer {
      margin-top: 10px; /* Adjust spacing as needed */
    }
  
    .innercontainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Each row has 4 columns */
      gap: 5px;
  
    }
  
    .listView {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 0;
      justify-content: space-evenly;
    }
  }
  .wrapper{
    width: 150px;
    height: auto;
    background-color: #E5E5E5 ;
    margin: 40px 40px;
    border-radius: 20px;
    padding-bottom: 10px;
  }
  .card {
    width: 150px;
    height: 100px;
    box-sizing: border-box;
    border-radius: 20px;
   
    display: inline-block;
   
  }
  .title {
    width: 100%;
    color: white;
    display: flex;
    font-size: medium;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "League Spartan", sans-serif;
    line-height: 10px;
    font-weight: 500;
    
    color: black;
  }
  .cimage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
  }
  
  /* .container{
      background-color: #f2f4f8;
      width: 100% ;
      height: 100vh;
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin-top: 0px;
      flex-grow: 1;
      position: relative;
  } */
  

  

  